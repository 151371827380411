import { defineStore } from 'pinia'
import { useUserStore } from '@/store/user'
import axios from '@/lib/axios'
import { useRouter } from 'vue-router'

const router = useRouter()

export const useAuthStore = defineStore({
    id: 'auth',

    state: () => {
        return {
            email: null,
            is_logged_in: false,
            token: null,
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: '@elemis:auth',
                storage: localStorage,
            },
        ],
    },

    actions: {
        async updateState(payload) {
            this.email = payload.email
            this.is_logged_in = payload.is_logged_in
            this.token = payload.token
        },

        async login({ email, password }) {
            const userStore = useUserStore()
            try {
                const { data } = await axios.post('/api/login', { email, password, remember: true })
                await this.updateState({ email, is_logged_in: true, token: data.access_token })
                await userStore.fetchUser()
            } catch (error) {
                console.log('Error at login: ', error.message)
                throw error
            }
        },

        async logout() {
            const user = useUserStore()

            // always clean localStorage before reset the state
            localStorage.clear()
            this.$reset()
            user.$reset()
            await router.push({ name: 'login' })
        },
    },
})
