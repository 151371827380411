import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import piniaPersist from 'pinia-plugin-persist'

import './assets/main.css'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const toastOptions = {
    containerClassName: 'elemis',
    maxToasts: 5,
    newestOnTop: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    timeout: 3000,
    hideProgressBar: true,
}

const pinia = createPinia()
pinia.use(piniaPersist)

const app = createApp(App)

app.use(router)
app.use(pinia)
app.use(router)
app.use(Toast, toastOptions)

app.mount('#app')
